import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 0;
  width: 100%;
  height: 100%;
`;

export const Title = styled.p`
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Content = styled.table`
  border-collapse: collapse;
  font-weight: 200;
  position: relative;
  width: 100%;

  background-color: var(--white);
`;

export const Header = styled.thead`
  position: sticky;
  top: -20px;
  z-index: 2;
  background-color: var(--white);
`;

export const Body = styled.tbody`
  overflow: auto;

  & tr:nth-child(odd) {
    background-color: var(--opacity-background);
  }

  & tr:nth-child(even) td{
    border: 1px solid var(--opacity-background);
  }

  & tr:nth-child(odd) td{
    border: 1px solid var(--white);
  }

  & tr td:not(:first-child) {
    text-align: right;
  }

  input {
    background-color: inherit;
  }

  select {
    background-color: inherit;
  }
`;

export const Row = styled.tr`
  position: relative;
`;

export const HeaderCell = styled.th<any>`
  white-space: nowrap;
  color: var(--primary-text-color);
  letter-spacing: 1.5px;
  font-weight: 200;
  font-size: 12px;
  text-align: left;
  text-transform: capitalize;
  padding: 4px;
  border-bottom: 1px solid var(--opacity-background);
  transition: .2s;

  & svg {
    font-size: 14px;
    top: 9px;
    margin-left: 4px;
    position: absolute;
  }

  &:first-child {
    padding: 8px;

    & button {
      background-color: transparent;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  &:nth-child(2) {
    padding: 4px 8px;
  }

  &:nth-child(n+2):hover {
    background-color: var(--opacity-background);
    cursor: pointer;
  }
`;

export const Cell = styled.td<any>`
  font-size: 14px;
  text-align: left;
  text-transform: capitalize;
  vertical-align: center;
  border-bottom: 1px solid var(--opacity-background);
  padding: 4px;
  white-space: nowrap;

  &:first-child {
    padding: 8px;
  }

  &:nth-child(2) {
    padding: 4px 8px;
  }
`;

export const Footer = styled.tfoot`
  position: sticky;
  bottom: 0;
  z-index: 2;
  background-color: var(--white);
`;

export const FooterContent = styled.div`
  border-top: 1px solid var(--opacity-background);
  border-bottom: 1px solid var(--opacity-background);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  height: 50px;

  @media (max-width: 559px) {
    flex-direction: column;
  }
`;

export const SelectedContainer = styled.div`
  display: flex;
  align-items: center;

  & > p {
    font-size: 12px;
    color: var(--primary-text-color);
    text-transform: uppercase;
    display: flex;
    align-items: center;

    & > b {
      font-weight: 500;
      margin-left: 4px;
    }
  }
`;

export const PaginateContainer = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin-left: 600px;


  & .active-page-item {
    background-color: var(--primary);
    color: var(--white);
  }

  ul {
    display: flex;
    list-style: none;
    justify-content: space-between;

    li {
      padding: 4px 8px;
      margin: 0 2px;
      cursor: pointer;
      color: var(--primary-text-color);
      background-color: var(--white);
      transition: .2s;
      border-radius: 2px;
      border: 1px solid var(--input-border-blurred);

      &:hover {
        background-color: var(--primary);
        color: var(--white);
      }

      &:active {
        background-color: var(--primaryDarker);
      } 
    }
  }
`;

export const ActionContainer = styled.div`
  padding: 0px 16px;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
`;

export const LinkButton = styled.button`
  background-color: transparent;
  color: var(--primary);
  cursor: pointer;
`;