import styled from 'styled-components';

import ButtonLayout from '../../components/Button';
import TextInputLayout from '../../components/TextInput';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

interface IHeaderProps {
  direction?: string;
}

export const HeaderContent = styled.div<IHeaderProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: 559px) {
    flex-direction: ${props => props.direction ? props.direction : 'row'};
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const HeaderCustomContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

interface ISearchInputProps {
  header?: string;
}

export const SearchInput = styled.input<ISearchInputProps>`
  height: 40px;
  border-radius: 4px;
  margin-top: -8px;
  margin-bottom: 16px;
  padding: 0 8px;
  display: ${props => props.header === "true" ? "none" : "block"};

  @media (max-width: 559px) {
    display: ${props => props.header === "true" ? "block" : "none"};
    margin-top: 8px;
    width: 100%;
  }
`;

export const Title = styled.p`
  font-size: 24px;

  & span {
    font-size: 14px;
    color: var(--muted);
  }
`;

export const Content = styled.div<any>`
  background-color: var(--white);
  min-height: 250px;
  max-height: 350px;
  width: ${props => props.width};
  padding: 16px;

  border-radius: 2px;
  border-left: 1px solid var(--opacity-background);
  border-right: 1px solid var(--opacity-background);
  border-top: 1px solid var(--opacity-background);
  overflow: auto;

  position: relative;

  h1 {
    width: 100%;
    font-size: 20px;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
  h2 {
    width: 100%;
    font-size: 18px;
    background-color: var(--secondary);
    color: var(--white);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }

  & div:first-child{
    margin-bottom: 8px;
  }

  &:first-child div:first-child{
    margin-bottom: 2px;
  }

  .lookup {
    width: 100%;
  }
`;

export const ActionsContainer = styled.div<IHeaderProps>`
  width: auto;
  display: flex;
  gap: 16px;

  & button {
    width: auto;
  }

  @media (max-width: 559px) {
    flex-direction: ${props => props.direction ? props.direction : 'row'};
    margin-top: ${props => props.direction && props.direction === 'column' ? '8' : '0'}px;
    width: ${props => props.direction && props.direction === 'column' ? '100%' : 'auto'};

    button {
      width: ${props => props.direction && props.direction === 'column' ? '100%' : 'auto'};
    }
  }
`;

export const Button = styled(ButtonLayout)`
  font-size: 12px;
  padding: 10px;

  @media (max-width: 559px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;

  & button {
    margin-top: 16px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  table{
    border: 1px solid var(--opacity-background);
    border-collapse: collapse;
    border-radius: 4px;
    font-size: 13px;
  }

  table tr {
    border-bottom: 1px solid var(--opacity-background);
  }

  table td {
    text-align: left;
    padding: 2px;
    border-right: 1px solid var(--opacity-background);
  }

  table td, table th {
    border-right: 1px solid var(--opacity-background);
  }

  table td.right {
    text-align: right;
  }

  table.month-trade {
    width: 100%;
    margin-top: 16px;
  }

  table.month-trade tr {
    border-bottom: 1px solid var(--opacity-background);
  }

  table.month-trade td {
    padding: 5px;
  }
  
  table.events {
    width: 100%;
    font-size: 12px;
    overflow-y:scroll;
  }

  table.events th {
    padding: 0px 2px;
  }

  table.events td.right {
    text-align: right;
  }
`;

export const TextInput = styled(TextInputLayout)``;