import React, {useEffect, useState} from 'react';
import { 
  Container, 
  Title, 
  Content, 
  Header,
  HeaderCell,
  Row,
  Body,
  Cell,
  LinkButton,
} from './styles';

import CellComponent from '../../components/ListTable/CellComponent';
import { getTrades } from '../../services/eventos';
import TextProcess from '../../components/TextProcess';
import { useHistory } from 'react-router-dom';

import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';

const Radar: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [dataOrder, setDataOrder] = useState<any[]>([]);

  useEffect(() => {
    getReports();
  }, []);

  async function getReports() {
    try {
      const result = await getTrades(null);
      formatData(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Erro: ', error)
    }
  }

  function formatData(baseData: any[]) {
    const newData = baseData.map((item: any) => {
      return {
        ticker: item._id.field1,
        percentual: parseFloat(item.agrupados[0].percentual),
        entDia: parseFloat(item.agrupados[0].entradaHoje),
        percLucro: parseFloat(item.agrupados[0].resultado),
        fatLucro: parseFloat(item.fatorLucro),
        volAnual: parseFloat(item.agrupados[0].volatilidade),
        qtdTrades: parseFloat(item.agrupados[0].qtdTrades),
        percTrades: parseFloat(item.agrupados[0].qtdTrades),
        qtdGanhos: parseFloat(item.agrupados[0].qtdGanhos),
        percTradesGanhos: parseFloat(item.agrupados[0].percGanhos),
        qtdPerdas: parseFloat(item.agrupados[0].qtdPerdas),
        percTradesPerdas: parseFloat(item.agrupados[0].percPerdas),
      }
    });
    setData(newData);
  }

  function goToXRay(symbol: string, percent: number) {
    const symbolName = symbol.split(' - ');
    history.push({
      pathname: '/raio-x',
      state: { symbol: symbolName[0], percent },
    });
  }

  function triggerOrder(column: string) {
    const newOrder = [...dataOrder];
    const index = newOrder.findIndex((item) => item.column === column);
    if (index === -1) {
      newOrder.push({ column, order: 'asc' });
    } else {
      if (newOrder[index].order === 'asc') {
        newOrder[index].order = 'desc';
      } else {
        newOrder.splice(index, 1);
      }
    }
    setDataOrder((oldData: any) => {
      return newOrder;
    });
    arrangeData(newOrder);
  }

  function arrangeData(order: any[]) {
    const newData = [...data];
    order.forEach((item) => {
      newData.sort((a, b) => {
        if (item.order === 'asc') {
          if (a[item.column] > b[item.column]) {
            return 1;
          }
          if (a[item.column] < b[item.column]) {
            return -1;
          }
          return 0;
        } else {
          if (a[item.column] < b[item.column]) {
            return 1;
          }
          if (a[item.column] > b[item.column]) {
            return -1;
          }
          return 0;
        }
      });
    });
    setData((oldData: any) => {
      return newData
    });
  }

  function checkOrder(column: string) {
    const index = dataOrder.findIndex((item) => item.column === column);
    if (index === -1) {
      return '';
    } else {
      if (dataOrder[index].order === 'asc') {
        return <AiOutlineArrowUp />;
      } else {
        return <AiOutlineArrowDown />;
      }
    }
  }

  if (loading) {
    return (
      <TextProcess message="Carregando" />
    )
  }

  return (
    <Container>
      <Title>Radar</Title>
      <Content>
        <Header>
          <Row>
            <HeaderCell id="ticker">
              Ticker
            </HeaderCell>
            <HeaderCell id="percentual" onClick={() => triggerOrder("percentual")}>
              Percentual {checkOrder("percentual")}
            </HeaderCell>
            <HeaderCell id="entDia" onClick={() => triggerOrder("entDia")}>
              Ent. Dia {checkOrder("entDia")}
            </HeaderCell>
            <HeaderCell id="percLucro" onClick={() => triggerOrder("percLucro")}>
              Lucro (%) {checkOrder("percLucro")}
            </HeaderCell>
            <HeaderCell id="fatLucro" onClick={() => triggerOrder("fatLucro")}>
              Lucro (f) {checkOrder("fatLucro")}
            </HeaderCell>
            <HeaderCell id="volAnual" onClick={() => triggerOrder("volAnual")}>
              Vol. Anual {checkOrder("volAnual")}
            </HeaderCell>
            <HeaderCell id="qtdTrades" onClick={() => triggerOrder("qtdTrades")}>
              Trades {checkOrder("qtdTrades")}
            </HeaderCell>
            <HeaderCell id="percTrades" onClick={() => triggerOrder("percTrades")}>
              Trades (%) {checkOrder("percTrades")}
            </HeaderCell>
            <HeaderCell id="qtdGanhos" onClick={() => triggerOrder("qtdGanhos")}>
              Ganhos {checkOrder("qtdGanhos")}
            </HeaderCell>
            <HeaderCell id="percTradesGanhos" onClick={() => triggerOrder("percTradesGanhos")}>
              Ganhos (%) {checkOrder("percTradesGanhos")}
            </HeaderCell>
            <HeaderCell id="qtdPerdas" onClick={() => triggerOrder("qtdPerdas")}>
              Perdas {checkOrder("qtdPerdas")}
            </HeaderCell>
            <HeaderCell id="percTradesPerdas" onClick={() => triggerOrder("percTradesPerdas")}>
              Perdas (%) {checkOrder("percTradesPerdas")}
            </HeaderCell>
          </Row>
        </Header>
        <Body>
          {data.map((item, index) => (
            <Row key={index}>
              <Cell>
                <LinkButton onClick={() => goToXRay(item.ticker, item.percentual)}>
                  {item.ticker}
                </LinkButton>
              </Cell>
              <Cell>
                <CellComponent
                  type="texto"
                  value={item.percentual}
                  editavel={false}
                />
              </Cell>
              <Cell>
                <CellComponent
                  type="texto"
                  value={item.entDia}
                  editavel={false}
                />
              </Cell>
              <Cell>
                <CellComponent
                  type="texto"
                  value={item.percLucro}
                  editavel={false}
                />
              </Cell>
              <Cell>
                <CellComponent
                  type="texto"
                  value={item.fatLucro}
                  editavel={false}
                />
              </Cell>
              <Cell>
                <CellComponent
                  type="texto"
                  value={item.volAnual}
                  editavel={false}
                />
              </Cell>
              <Cell>
                <CellComponent
                  type="texto"
                  value={item.qtdTrades}
                  editavel={false}
                />
              </Cell>
              <Cell>
                <CellComponent
                  type="texto"
                  value={item.percTrades}
                  editavel={false}
                />
              </Cell>
              <Cell>
                <CellComponent
                  type="texto"
                  value={item.qtdGanhos}
                  editavel={false}
                />
              </Cell>
              <Cell>
                <CellComponent
                  type="texto"
                  value={item.percTradesGanhos}
                  editavel={false}
                />
              </Cell>
              <Cell>
                <CellComponent
                  type="texto"
                  value={item.qtdPerdas}
                  editavel={false}
                />
              </Cell>
              <Cell>
                <CellComponent
                  type="texto"
                  value={item.percTradesPerdas}
                  editavel={false}
                />
              </Cell>
            </Row> 
          ))}
        </Body>
      </Content>
    </Container >
  );
}

export default Radar;