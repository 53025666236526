/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import parseDate from "date-fns/parse";
import ptBR from "date-fns/locale/pt-BR";
import { Column } from "./types";
import { TableContainer, TableBody, Disabled } from "./styles";
import TableHead from "./Head";
import TableToolBar from "./Toolbar";
import TableRow from "./Row";
import axios from "axios";
import { BASE_URL } from "../../environment/stores";
import { useAuth } from "../../contexts/auth";
import { getChavePedido } from "../../services/pedidos";

import { treatColField } from "../../utils/form";

import { isValidDate } from "../../utils/date";
import { formatCnpj, formatCpf } from "../../utils/form";

export interface TableProps {
  title?: string;
  cols?: Column[];
  actions?: string[];
  searchCol?: string;
  loadState?: any;
  item?: any;
  fromForm?: boolean;
  isRecurso?: boolean;
  isTitulo?: boolean;
  chaveCriacao?: number;
  lookupsRegras?: any;
  onChange?: any;
  initialValue?: any[];
  fixedValues?: any[];
  fixedValue?: any;
  withTotal?: boolean;
  disabled?: boolean;
  type?: string;
  total?: any[];
  styles?: Object;
  importXML?: boolean;
  changeSelectedIndexes?: any;
}

function Table({
  title,
  cols,
  item,
  actions,
  // searchCol,
  initialValue,
  fixedValue,
  fixedValues,
  loadState,
  fromForm,
  isRecurso,
  isTitulo,
  lookupsRegras,
  onChange,
  withTotal,
  disabled,
  total,
  type,
  styles = {
    position: 'relative',
  },
  importXML,
  changeSelectedIndexes
}: TableProps) {
  const { user } = useAuth();
  const [data, setData] = useState<any[]>([]);
  const [loadedInitialState, setLoadedInitialState] = useState(false);
  const [fields, setFields] = useState<any>(null);
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);

  useEffect(() => {
    if (loadState && item && cols) {
      getData({
        endpoint: loadState.endpoint,
        filters: {
          classe: loadState.filter.classe,
          [loadState.filter.field]: item.id,
        },
      });
    }
  }, [loadState, item, cols]);

  useEffect(() => {
    if (fromForm) {
      onChange(data);
    }
  }, [data]);

  useEffect(() => {
    if (initialValue && !loadedInitialState && !isRecurso) {
      setData(initialValue);
      setLoadedInitialState(true);
    }
  }, [initialValue, loadedInitialState]);

  useEffect(() => {
    if (initialValue && !loadedInitialState && isRecurso) {
      loadInitialValueKeys(initialValue);
      setLoadedInitialState(true);
    }
  }, [initialValue, isRecurso, isTitulo, loadedInitialState]);

  useEffect(() => {
    if(changeSelectedIndexes){
      changeSelectedIndexes(selectedIndexes)
    }
  }, [selectedIndexes])

  const handleRowActions = (index: any) => {
    if (actions) {
      const initialActions: any = [];

      if (actions.includes("select")) {
        initialActions.push({
          name: "select",
          handler: () => onSelected(index),
        });
      }
      if (actions.includes("delete")) {
        initialActions.push({
          name: "remove",
          handler: () => onDelete(index),
        });
      }

      return initialActions;
    }
  };

  async function loadInitialValueKeys(values: any[]) {
    if (values.length > 0 && (values[0].hasOwnProperty('chave') || isTitulo)) {
      if (isTitulo) {
        setData(values);
      } else {
        return setData(values);
      }
    }
    for (let i = 0; i < values.length; i++) {
      if (i === 0) {
        setData([]);
      }
      await getKeyAndInsertItem(i !== 0);
    }
  }

  async function getKeyAndInsertItem(add: boolean = false, fromInsert = false) {
    try {
      const result = await getChavePedido(user?.id);
      
      if (add) {
        let dt: any = { chave: result.chave };
        if (fromInsert) {
          if (data.length === 0 && isTitulo) {
            dt = { ...dt };
          }
          setData((oldData) => {
            return [...oldData, dt];
          });
        } else {
          setData((oldData) => {
            const newIndex = oldData.length;
            if (
              initialValue &&
              initialValue.length > 0 &&
              initialValue[newIndex]
            ) {
              dt = { ...dt, ...initialValue[newIndex] };
            }
            return [...oldData, dt];
          });
        }
      } else {
        let dt: any = { chave: result.chave };
        if (isTitulo) {
          dt = { ...dt };
        }
        if (initialValue && initialValue.length > 0) {
          dt = { ...dt, ...initialValue[0] };
        }
        setData((oldData) => {
          return [dt];
        });
      }
    } catch (err: any) {
      getKeyAndInsertItem(add);
      console.log(err);
    }
  }

  async function getData({ endpoint, filters }: any) {
    if (!cols) return;
    try {
      const response = await axios.get(`${BASE_URL}/${endpoint}/`, {
        params: filters,
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      setData(response.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (cols) {
      adjustStartCols();
    }
  }, [cols]);

  function adjustStartCols() {
    if (!cols) return;
    const colsField: any = {};

    for (const col of cols) {
      if (col.campo && col.nome) {
        const field = treatColField(
          col,
          () => {},
          () => {}
        );

        if (field) {
          colsField[col.campo] = field;
        }
      }
    }

    if (Object.keys(colsField).length > 0) setFields(colsField);
  }

  const formatPayload = (values: any) => {
    let payload: any = {};
    for (const formFieldKey of Object.keys(values)) {
      const value = values[formFieldKey];
      if (value) {
        if (typeof value === "object" && value.hasOwnProperty("value")) {
          const idKey = `id${formFieldKey}`;
          payload = {
            ...payload,
            [idKey]: value === null ? value : value.value,
          };
        } else {
          const isDate = isValidDate(value);
          if (isDate) {
            payload = {
              ...payload,
              [formFieldKey]: parseDate(
                value,
                isDate === "date" ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm",
                new Date(),
                { locale: ptBR }
              ),
            };
          } else {
            if (formFieldKey === "cpfCnpj") {
              if (value === null || value === "") {
                payload[formFieldKey] = null;
              } else {
                let adjustedValue = value.replace(/\D/g, "");
                if (adjustedValue.length < 12) {
                  adjustedValue = formatCpf(adjustedValue);
                } else {
                  adjustedValue = formatCnpj(adjustedValue);
                }
                payload[formFieldKey] = adjustedValue;
              }
            } else {
              payload[formFieldKey] = value === "" ? null : value;
            }
          }
        }
      }
    }

    return payload;
  };

  const onInsert = () => {
    if (isRecurso) {
      getKeyAndInsertItem(true, true);
    } else {
      setData([...data, {}]);
    }
  };

  const onDelete = (index: number) => {
    const newData = data.filter((rowData, idx) => idx !== index);
    setData(newData);
  };

  const onSelected = (index: number) => {
    const newSelected = [...selectedIndexes];
    newSelected[index] = newSelected[index] === true ? false : true;

    setSelectedIndexes(newSelected);
  };

  const handleRowChange = (index: number, values: any) => {
    const payload = formatPayload(values);
    console.log('Item: ', index);
    console.log(new Date().toISOString());
    setData((oldValue) => {
      oldValue[index] = payload;
      return oldValue;
    });
  };

  const updateFormaPagamento = (_newData: any) => {
    setData([...data, ..._newData]);
  };

  const handleActions = () => {
    if (actions && cols) {
      if (disabled) return cols;

      let initialActions: any = [];

      if (actions.includes("select")) {
        initialActions.push({ name: "checkbox", handler: (value: boolean) => selectAll(value) });
      }
      if (actions.includes("delete")) {
        initialActions.push({ name: "X" });
      }

      initialActions = [...initialActions, ...cols];
      return initialActions;
    } else if(cols){
      return cols
    } else{
      return []
    }
  };

  const selectAll = (value: boolean) => {
    const newSelected = new Array(data.length).fill(value);

    setSelectedIndexes(newSelected);
  };

  return (
    <div style={styles}>
      <TableToolBar
        title={title}
        actions={disabled ? [] : actions}
        onInsert={onInsert}
        updateFormaPagamento={
          title === "Formas de Pagamento" ? updateFormaPagamento : null
        }
      />
      <TableContainer>
        <TableHead columns={handleActions()} />
        <TableBody>
          {data.map((dataRows, index) => (
            <React.Fragment key={index}>
            {disabled && <Disabled />}
            <TableRow
              disabled={disabled}
              dataRow={dataRows}
              isRecurso={isRecurso}
              isFormaPagamento={title === "Formas de Pagamento"}
              type={type}
              lookupsRegras={
                initialValue
                  ? null
                  : lookupsRegras
              }
              loadInitialState={loadedInitialState}
              importXML={importXML}
              fixedValue={
                fixedValues && fixedValues.length > 0
                  ? {
                      ...fixedValue,
                      ...fixedValues[index],
                    }
                  : fixedValue
              }
              fields={fields}
              checked={selectedIndexes[index]}
              columns={cols ? cols : []}
              onRowChange={(row: any) => handleRowChange(index, row)}
              rowActions={handleRowActions(index)}
            />
            </React.Fragment>
          ))}
          {withTotal && cols && total && (
            <tr>
              {actions?.includes("delete") && !disabled && <td></td>}
              {cols.map((col, ind) => {
                const isOnTotal = total.filter(
                  (totalCol) => col.campo === totalCol.field
                )[0];

                if (isOnTotal) {
                  return (
                    <td key={ind} style={{textAlign: 'right', paddingRight: 8}}>
                      <span>Total</span>
                      <br />
                      {isOnTotal.value}
                    </td>
                  );
                } else {
                  return <td key={ind}></td>;
                }
              })}
            </tr>
          )}
        </TableBody>
      </TableContainer>
    </div>
  );
}

export default Table;

// actions?.includes('delete') && cols && !disabled ? [{
//   name: 'X',
// }, ...cols] : cols ? cols : []
