import provider from "./config";

export const getEventsReport = async (params:any) => {
    try {
      const response = await provider.get('/relatorios/eventos', {
        params: params
      });
      return response.data;
    } catch (error) {
      throw Error("Erro ao buscar relatório de eventos");
    }
  }

export const getTrades = async (params:any) => {
  try {
    const response = await provider.get('/relatorios/trades', {
      params: params
    });
    return response.data;
  } catch (error) {
    throw Error("Erro ao buscar relatório de trades");
  }
}

export const getTradeInfo = async (params:any) => {
  try {
    const response = await provider.get('/eventos/trade', {
      params: params
    });
    return response.data;
  } catch (error) {
    throw Error("Erro ao buscar as informações deste código");
  }
}

export const updateTrade = async (id: string, body:any) => {
  try {
    const response = await provider.put(`/recursos/codigo/${id}`, body);
    return response.data;
  } catch (error) {
    throw Error("Erro ao atualizar este código");
  }
}