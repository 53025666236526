/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Container,
  Title,
  Content,
  DivRow,
} from "./styles";
import Button from "../../components/Button";
import ListTable, { IField } from "../../components/ListTable";
import { IToggleMenuOption } from "../../components/ToggleMenuButton";
import useFetch from "../../hooks/useFetch";
import { orderCols } from "../../utils/form";
import { Footer } from "../../components/Template/styles";
import {
  PaginateContainer,
  SelectedContainer,
} from "../../components/ListTable/styles";
import ReactPaginate from "react-paginate";
import Toast from "../../components/Toast";
import { getItemsAndTitulos } from "../../services/pedidos";

interface IAction {
  id: string;
  name: string;
  type: "global" | "specific";
  onClick(item?: any): void;
}

interface IRequests {
  title: string;
  multipleSelect: boolean;
  endpoint: string;
  filters?: any;
  path?: string;
  actions: Array<IAction>;
}

interface IResponse {
  docs: any[];
  totalPages: number;
  total: number;
}

interface IBaseHandler {
  [key: string]: any;
}

const Requests: React.FC<IRequests> = ({
  title,
  multipleSelect,
  endpoint,
  filters,
  actions,
  path,
}) => {
  const requestHandler = useFetch({ endpoint }) as any;
  const fields = useFetch({ endpoint: `${endpoint}/campos/` });
  const history = useHistory();
  const location = useLocation<any>();
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [getResponse, setGetResponse] = useState<IResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [actionsMenus, setActionsMenus] = useState<IToggleMenuOption[]>([]);

  useEffect(() => {
    if (location && location.state) {
      const details = location.state.filtersToGet;

      getTableFields(details);
    } else {
      getTableFields();
    }
  }, [currentPage]);

  useEffect(() => {
    const specificActions = actions.filter(
      (action) => action.type === "specific"
    );
    const baseKeys = Object.keys(baseActionHandlers);

    if (specificActions.length > 0) {
      const rowActions = specificActions.map(
        (action: IAction, index: number) => {
          const option: IToggleMenuOption = {
            id: index,
            name: action.name,
            onClick: baseKeys.includes(action.id)
              ? (item: any) => baseActionHandlers[action.id](item)
              : action.onClick
              ? () => action.onClick()
              : () => {},
          };

          return option;
        }
      );
      setActionsMenus(rowActions);
    }
  }, []);

  const getTableFields = async (details?: any) => {
    try {
      const cols = await fields.get(filters);
      if (cols) {
        const order_cols = cols.sort(orderCols);
        setCols(order_cols);

        if (details) {
          const result = await requestHandler.get({
            page: currentPage,
            ...filters,
            ...details,
          });
          if (result) {
            console.log(result);
            setGetResponse(result);
          }
        } else {
          const result = await requestHandler.get({
            page: currentPage,
            ...filters,
          });
          if (result) {
            setGetResponse(result);
          }
        }
      }
    } catch (err: any) {
      console.error("Erro", err.message);
    }
  };
console.log(selectedIndexes)
  const handleEdit = async (item?: any) => {
    if (path && path === "/requisicoes/resultado") {
      if(item){
        let itemToSend = {
          items: [item],
        };
        
        history.push({
          pathname: `/requisicoes/editar/`,
          state: {
            item: itemToSend,
          },
        });

      } else{
        if(getResponse){
          let selection = [...selectedIndexes]
          let docs = [...getResponse.docs]
          let itemSelected: any = [];
          
          selection.map((it: any, index: any) => {
            if(it === true){
              itemSelected.push(docs[index])
            }
            return null;
          })
          let itemToSend = {
            items: itemSelected,
          };

          history.push({
            pathname: `/requisicoes/editar/`,
            state: {
              item: itemToSend,
            },
          });
        }
      }
    } else if (path && (item.id || item.chcriacao)) {
      let itemToSend = {
        ...item,
      };

      if (item.chcriacao) {
        try {
          const result = await getItemsAndTitulos([item.chcriacao]);
          console.log("result: ", result);
          itemToSend = {
            ...itemToSend,
            items: result.items,
            titulos: result.titulos,
          };
        } catch (err: any) {
          return Toast.show(err.message, "error");
        }
      }

      history.push({
        pathname: `/requisicoes/editar/${item.id ? item.id : item.chcriacao}`,
        state: {
          item: itemToSend,
        },
      });
    }
  };

  const baseActionHandlers: IBaseHandler = {
    edit: handleEdit,
    // pullOrder: () => setBaixaPedido(true),
    // remove: handleRemove,
    // fichaFuncionario: handleFichaFuncionario,
  };

  const handleChangePage = (page: number) => {
    if (page + 1 !== currentPage) {
      setCurrentPage(page + 1);
    }
  };

  return (
    <Container>
      <DivRow>
        <Title>{title}</Title>
        <div>
          <Button
            form="custom-form"
            type="submit"
            id="editar"
            name="editar"
            onClick={() => handleEdit()}
          >
            Editar
          </Button>
        </div>

      </DivRow>
      <Content>
        <ListTable
          multipleSelect={multipleSelect}
          changeSelectedIndexes={setSelectedIndexes}
          fields={cols}
          data={getResponse ? getResponse.docs : []}
          onChangePage={handleChangePage}
          currentPage={currentPage}
          totalPages={getResponse ? getResponse.totalPages : 1}
          count={getResponse ? getResponse.total : 0}
          rowMenuOptions={actionsMenus}
          withFooter={false}
        />
      </Content>
      <Footer>
        <SelectedContainer>
          <p>
            Total: <b>{getResponse ? getResponse.total : 0}</b>
          </p>
        </SelectedContainer>
        <PaginateContainer>
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={({ selected }) => handleChangePage(selected)}
            pageRangeDisplayed={5}
            pageCount={getResponse ? getResponse.totalPages : 1}
            previousLabel="< "
            renderOnZeroPageCount={undefined}
            activeClassName="active-page-item"
          />
        </PaginateContainer>
      </Footer>
    </Container>
  );
};

export default Requests;
