import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {isValid } from 'date-fns';
import formatDateFns from 'date-fns/format';
import ptBr from 'date-fns/locale/pt-BR';
import LookupInput from "../../components/LookupInput";
import { getTradeInfo, updateTrade } from "../../services/eventos";
import { formatMoney } from "../../utils/format";
import {
  Wrapper,
  HeaderContent,
  Title,
  ActionsContainer,
  Button,
  Content,
  Row,
  Col,
  TextInput,
} from "./styles";
import { addHours } from "date-fns/esm";
import { useLocation } from "react-router-dom";

const Trade: React.FC = () => {
  const location = useLocation<any>();
  const meses = [
    "Janeiro",
    "Feveiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const [payload, setPayload] = useState<any>({
    percentual: 0,
    qtdEventos: 100,
    CodigoRecurso: null,
  });
  const [initialLookups, setInitialLookups] = useState<any>({
    Codigo: null,
  });
  const [info, setInfo] = useState<any>(null);

  useEffect(() => {
    if (payload.CodigoRecurso && payload.CodigoRecurso.item && payload.CodigoRecurso.item.fatorConversao) {
      setPayload({
        ...payload,
        percentual: payload.CodigoRecurso.item.fatorConversao,
      });
    }
  }, [payload.CodigoRecurso]);

  useEffect(() => {
    if (location.state) {
      if (location.state.symbol) {
        setInitialLookups((oldData: any) => {
          return {
            ...oldData,
            Codigo: { byName: location.state.symbol },
          }
        });
      }
      if (location.state.percent) {
        setPayload((oldData: any) => {
          return {
            ...oldData,
            percentual: location.state.percent || 0,
          }
        });
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (initialLookups.Codigo && payload.CodigoRecurso && payload.CodigoRecurso.item) {
      getData();
    }
  }, [initialLookups, payload]);

  const changePayload = (key: string, value: any) => {
    setPayload({ ...payload, [key]: value });
  };

  const getData = async () => {
    if (!payload.CodigoRecurso) {
      return toast.error("Selecione um código");
    } else if (!payload.qtdEventos || payload.qtdEventos <= 0) {
      return toast.error("Insira a quantidade de eventos para buscar");
    }
    try {
      const response = await getTradeInfo({
        idCodigoRecurso: payload.CodigoRecurso ? payload.CodigoRecurso.value : null,
        percentual: payload.percentual,
        qtdEventos: payload.qtdEventos,
      });
      setInfo(response);
    } catch (err: any) {
      return toast.error(err.message);
    }
  }

  const savePercent = async () => {
    if (!payload.CodigoRecurso) {
      return toast.error("Selecione um código");
    }
    try {
      await updateTrade(payload.CodigoRecurso.value, {
        fatorConversao: payload.percentual
      });
      return toast.success("Percentual atualizado com sucesso");
    } catch (err: any) {
      return toast.error(err.message);
    }
  }

  function getMoney(baseValue: any) {
    let value = baseValue;
    if ((value < 1 && value > 0) || (value < 0 && value > -1)) {
      value = String(value).replace('.', ',');
    } else {
      let val: any = formatMoney(value);
      val = val.split('R$');
      value = val.join('').trim();
    } 
    return value;
  }

  async function handleKeyDown(e: any) {
    if (e.keyCode === 13 ) {
      await getData();
    }
  }

  return (
    <Wrapper>
      <HeaderContent direction="row">
        <Title>Trade</Title>
        <ActionsContainer direction="row">
          <Button onClick={savePercent}>Salvar</Button>
        </ActionsContainer>
      </HeaderContent>
      <Row>
        <Content width={"18%"}>
          <Row>Variáveis</Row>
          <Row>
            <LookupInput
              // disabled={comAprovador || xml}
              label="Código"
              initialState={initialLookups.Codigo}
              endpoint="recursos/codigos"
              field="nome"
              labelField="codigo"
              multi={false}
              onChange={(options) =>
                changePayload("CodigoRecurso", options)
              }
            />
          </Row>
          <Row>
            <TextInput
              label="Percentual"
              id="percentual"
              fixed
              money
              masked
              value={payload.percentual}
              onKeyDown={handleKeyDown}
              onChange={({target}) => changePayload('percentual', target.value)}
            />
            <TextInput
              label="Qtd Eventos"
              id="qtdEventos"
              fixed
              masked
              mask="integer"
              value={payload.qtdEventos}
              onKeyDown={handleKeyDown}
              onChange={({target}) => changePayload('qtdEventos', target.value)}
            />
          </Row>
          <Row>
            <Button onClick={getData}>Atualizar</Button>
          </Row>
        </Content>
        <Content width={"81%"}>
          <Row>Métricas</Row>
          <Row>
            <Col>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Recurso:</b>
                    </td>
                    <td>{info ? info.recurso : "-"}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Código:</b>
                    </td>
                    <td>{info ? info.codigo : "-"}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Entrada hoje:</b>
                    </td>
                    <td className="right">
                      {info && info.entradaHoje && info.entradaHoje !== "NaN" ? `R$ ${info.entradaHoje}` : "R$ 0,00"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Resultado:</b>
                    </td>
                    <td className="right">
                      {info && info.resultado && info.resultado !== "NaN" ? `${info.resultado}%` : "0.00%"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Fator de Lucro:</b>
                    </td>
                    <td className="right">
                      {info && info.fatorLucro && info.fatorLucro !== "NaN" ? `${info.fatorLucro}` : "0.00"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Lucro:</b>
                    </td>
                    <td className="right">
                      R$ {info ? getMoney(info.lucro) : "0,00"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Qtd Dias:</b>
                    </td>
                    <td className="right">{info && info.qtdDias}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Qtd Trades:</b>
                    </td>
                    <td className="right">{info && info.qtdTrades}</td>
                    <td className="right">{info && info.percTrades}%</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Qtd Ganhos:</b>
                    </td>
                    <td className="right">{info && info.qtdGanhos}</td>
                    <td className="right">{info && info.percGanhos}%</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Qtd Perdas:</b>
                    </td>
                    <td className="right">{info && info.qtdPerdas}</td>
                    <td className="right">{info && info.percPerdas}%</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Média Gain:</b>
                    </td>
                    <td className="right">
                      {info && info.mediaGain && info.mediaGain !== "NaN" ? `${info.mediaGain}%` : "0.00%"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Média Loss:</b>
                    </td>
                    <td className="right">
                      {info && info.mediaLoss && info.mediaLoss !== "NaN" ? `${info.mediaLoss}%` : "0.00%"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Max Gain:</b>
                    </td>
                    <td className="right">
                      {info && info.maxGain && info.maxGain !== "NaN" ? `${info.maxGain}%` : "0.00%"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Max Loss:</b>
                    </td>
                    <td className="right">
                      {info && info.maxLoss && info.maxLoss !== "NaN" ? `${info.maxLoss}%` : "0.00%"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col>
              <table className="month-trade">
                <thead>
                  <tr>
                  {meses.map((mes, index) => (
                    <th key={index}>{mes}</th>
                  ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {info && info.lucroMensal && info.lucroMensal.map((value: any, index: number) => (
                      <td className="right" key={index}>{value === null ? '-' : `R$ ${getMoney(value)}`}</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Content>
      </Row>
      <br />
      <Content>
        <Row>Registros</Row>
        <Row>
          <Col>
            <table className="events">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Abertura (R$)</th>
                  <th>Máxima (R$)</th>
                  <th>Mínima (R$)</th>
                  <th>Fechamento (R$)</th>
                  <th>% Mínima</th>
                  <th>% Fechamento</th>
                  <th>Pr Compra Dia (R$)</th>
                  <th>% Resultado Dia</th>
                  <th>Lucro Dia (R$)</th>
                </tr>
              </thead>
              <tbody>
                {info && info.eventos && info.eventos.map((_it: any, idx: number) => {
                  const _data = _it.data;
                  let data: any = addHours(new Date(_data), 3);
                  
                  if(isValid(data)){
                    data = formatDateFns(data, 'dd/MM/yy eeeeee', {
                      locale: ptBr,
                    });
                  } else{
                    data = ''
                  }
                  return (
                  <tr key={idx}>
                    <td>{data}</td>
                    <td className="right">
                      {getMoney(_it.open)}
                    </td>
                    <td className="right">
                      {getMoney(_it.high)}
                    </td>
                    <td className="right">
                      {getMoney(_it.low)}
                    </td>
                    <td className="right">
                      {getMoney(_it.close)}
                    </td>
                    <td className="right">
                      {_it.percMinima.toFixed(2)}
                    </td>
                    <td className="right">
                      {_it.percFechamento.toFixed(2)}
                    </td>
                    <td className="right">
                      {getMoney(_it.prCompraDia)}
                    </td>
                    <td className="right">
                      {_it.perc.toFixed(2)}
                    </td>
                    <td className="right">
                      {getMoney(_it.lucro)}
                    </td>
                  </tr>
                ) })}
              </tbody>
            </table>
          </Col>
        </Row>
      </Content>
    </Wrapper>
  );
};

export default Trade;
