import provider from './config';

export const getTitulosCondicao = async (payload: any): Promise<any> => {
    try {
      const response = await provider.post('/pedidos/gerar-titulos-condicao/', payload);
  
      return response.data;
    } catch (err: any) {
      throw Error("Ocorreu um erro ao tentar gerar os títulos da condição de pagamento");
    }
}

export const criarRequerimento = async (payload: any): Promise<any> => {
  try {
    const response = await provider.post('/requisicoes/', payload);

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar criar o requerimento");
  }
}

export const baixarRequerimento = async (payload: any): Promise<any> => {
  try {
    const response = await provider.post('/requisicoes/baixa/', payload);

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar baixar o requerimento")
  }
}

export const getItems = async (payload: any): Promise<any> => {
  try {
    const response = await provider.get(`/requisicoes/items/${payload}`);

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar os itens da requisição")
  }
}

export const deleteItem = async (chcriacao: any): Promise<any> => {
  try {
    const response = await provider.delete(`/requisicoes/${chcriacao}`);

    return response
  } catch (err: any) {
    throw Error("Occoreu um erro ao excluir o item da requisição")
  }
}

export const calcularRequerimento = async (payload: any): Promise<any> => {
  try {
    const response = await provider.post('requisicoes/calcula-custo', payload);

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar calcular o requerimento")
  }
}

export const updateRequerimento = async (payload: any): Promise<any> => {
  try {
    const response = await provider.put(`/requisicoes/`, payload);

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar atualizar o requerimento");
  }
}

export const deleteOrdersRequerimento = async (id: any, chCriacao: number, chaves?: any[]): Promise<any> => {
  const params: any = {
    usuario: id,
  };

  if (chaves && chaves.length > 0) {
    params.chaves = chaves.join(',');
  }

  try {
    const response = await provider.delete(`/requisicoes/apagar/${chCriacao}`, {
      params
    })

    return response
  } catch (err: any) {
    throw Error ("Ocorreu um erro ao tentar excluir o requerimento")
  }
}

export const getBaixaRequerimento = async (chcriacao: any, baixa: any): Promise<any> => {
  try {
    const response = await provider.get('/requisicoes/', {
      params: {
        id: chcriacao,
        baixa,
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar a baixa");
  }
}

export const getMovimentacoesRequerimento = async (chaves: any[]): Promise<any> => {
  try {
    const response = await provider.get('/requisicoes/movimentacoes/', {
      params: {
        chaves: chaves.join(','),
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar as movimentações destas requisições");
  }
}

export const getItemsAndTitulos = async (chaves: number[], all = false): Promise<any> => {
  try {
    const response = await provider.get('/pedidos/items-titulos', {
      params: {
        chaves: chaves.join(','),
        all,
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar os items e títulos");
  }
}

export const getReportRequerimento = async (page: number) => {
  try {
    const response = await provider.get('/requisicoes/', {
      params: {
        page: page,
        classe: 'Rendas',
        baixa: true
      }
    })

    return response.data
  } catch (err: any) {
    throw Error("Erro ao buscar os requerimentos de saída.")
  }
}

export const getPurchaseReport = async (params:any) => {
  try {
    const response = await provider.get('/relatorios/entradas', {
      params: params
    });
    return response.data;
  } catch (error) {
    throw Error("Erro ao buscar relatório Pedido de entradas");
  }
}

export const checkCHacessoNF = async (chacessonf: string) => {
  try {
    const response = await provider.get('/pedidos/xml', {
      params: {
        chacessonf
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar a chave de acesso da NFe");
  }
}