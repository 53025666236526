/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useHistory, RouteComponentProps } from 'react-router-dom'
import ptBR from 'date-fns/locale/pt-BR';
import { formatPhoneNumber, treeify, formatCNPJ, formatDate, formatGroupBy, formatDateHorsComDiaSemana } from '../../utils/format';
import {
  Button,
  Container,
  DivItems,
  FiltersContainer,
  LogoFooter,
  Main,
  RightButtons,
  Row,
} from './styles';
import LogoDevari from '../../assets/images/logo.png'
import ReactToPrint from 'react-to-print'
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { FaPlusSquare, FaMinusSquare } from 'react-icons/fa';
import { DivRow } from './styles';
import { getProviders, getStockExtract } from '../../services/providers';
import { getClasseAndChilds } from '../../services/classes';
import { useFormModal } from '../../contexts/formModal';
import FormTemplate from '../../components/Template/forms';
import Toast from '../../components/Toast';
import { getResources } from '../../services/resource';
import { getLicenseInfo } from '../../services/license';
import { convertToDate } from '../../utils/date';
import ResumeFiltersReport from '../../components/ResumeFiltersReport';
import format from 'date-fns/format';
import { VERSION } from '../../environment/stores';
interface IReport {
  Cidade?: ICidade;
  Classe?: IClasse;
  UF?: IUf;
  atualizadoEm?: string;
  bairro?: string;
  cep?: string;
  codigo?: string;
  criadoEm?: string;
  endereco?: string;
  id?: string;
  inscricaoEstadual?: string;
  nome?: string;
  nomeFantasia?: string;
  numeroEndereco?: string;
  telefone?: string;
  celular?: string;
}

interface ICidade {
  id?: string;
  nome?: string;
}

interface IClasse {
  id?: string;
  nome?: string;
}

interface IUf {
  id?: string;
  nome?: string;
  codigo?: string;
}

interface IField {
  label: string;
  field: string;
  orderName?: string;
}

interface Props extends RouteComponentProps {
  title: string;
  endpoint: string;
  filters: any;
  extraction?: any;
}

const ORDER_FIELD_PRODUCTS: IField[] = [
  { label: 'Classe', field: 'Classe' },
  { label: 'Código', field: 'codigo' },
  { label: 'Nome', field: 'nome' },
  { label: 'Un. Medida', field: 'UnidadeMedida' },
  { label: 'EAN', field: 'ean' },
  { label: 'NCM', field: 'NCM' },
  { label: 'Procedência', field: 'Procedencia' },
]

const ProductsReport: React.FC<Props> = ({ title, endpoint, filters }) => {

  const location = useLocation<any>();
  const history = useHistory<any>();
  const { openModal, closeModal } = useFormModal();
  const componentRef = useRef(null);
  const [reports, setReports] = useState<Array<IReport> | any>([]);
  const [marcaTexto, setMarcaTexto] = useState(false);
  const [gerarCabecalho, setGerarCabecalho] = useState(false);
  const [openList, setOpenList] = useState<Array<string>>([]);
  const [hasGroup, setHasGroup] = useState(false);
  const [agrupamento, setAgrupamento] = useState(false);
  const [fields, setFields] = useState<IField[]>([])
  const [classes, setClasses] = useState([]);
  const [group, setGroup] = useState('');
  const [license, setLicense] = useState<any>(null);
  const [filterLabel, setFilterLabel] = useState<any>([])

  const date = new Date();

  useEffect(() => {
    getLicense();
  }, []);

  useEffect(() => {
    if (location.state) {
      const details = location.state.filtersToGet
      const _filterLabels = location.state.filtersLabels
      
      if(_filterLabels) {
        setFilterLabel(_filterLabels)
      }

      if (details.marcaTexto) {
        setMarcaTexto(true)
        delete details.marcaTexto
      }
      if (details.gerarCabecalho) {
        setGerarCabecalho(true)
        delete details.gerarCabecalho
      }

      if (details.idAgrupamento || (details.Agrupamento && details.Agrupamento.length > 0)) {
        setHasGroup(true);
        if (details.idAgrupamento === "Classe") {
          setGroup(details.idAgrupamento);
          getClasses(details);
        }
      }

      if (details.Agrupamento && details.Agrupamento.length > 0) {
        setAgrupamento(true);
      }

      setFields(ORDER_FIELD_PRODUCTS);
      getReports(details);
    }
  }, [])

  async function getLicense() {
    try {
      const result = await getLicenseInfo();
      setLicense(result);
    } catch (error: any) {
      Toast.show(error, 'error');
    }
  }

  async function getClasses(details: any) {
    try {
      const { idClasse } = details;
      const result = await getClasseAndChilds({ id: idClasse });
      const resultClasse: any = treeify(result, 'idPai');
      setClasses(resultClasse);
    } catch (error: any) {
      Toast.show(error, 'error');
    }
  }
  
  async function getReports(details: any) {
    let result;
    try {
      if(filters.classe === 'Fornecedores') {
        result = await getProviders(details)
      } else if (filters.classe === 'Extrato') {
        result = await getStockExtract(details);
      }else {
        result = await getResources(details);
      }

      if (result) {
        if (Array.isArray(result)) {
          if (details.Agrupamento && details.Agrupamento.length > 0) {
            result = formatGroupBy(result);
          }
          setReports(result)
        } else {
          const resultObject = treatObject(result)
          setReports(resultObject)
        }
      }
    } catch (error) {
      console.error('Erro: ', error)
    }
  }

  function treatObject(object: any) {
    const keys = Object.keys(object)
    let arrayObject: any = []
    for (const key of keys) {
      const base: any = {
        Agrupador: key,
        custo: null,
        quantidade: null,
        isGroup: true,
        active: false,
        children: []
      };
      const value = object[key]
      if (Array.isArray(value)) {
        base.children = value;
      } else {
        const objectChild: any = treatObject(value);
        base.children = [...objectChild];

      }
      arrayObject.push(base);
    }
    return arrayObject;
  }

  const pageStyle = `
    @media print{
      html, body{
        border-collapse: collapse;
        height: initial !important;
        overflow: initial !important;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
      }
      table, th, td {
        border-collapse: collapse;
        white-space: normal;
      }
      .page-break {
        margin-top: 20px !important;
        display: block;
        page-break-before: auto;
      }

      div:nth-child(2) {
        height: auto;
      }
    }
    @page{
      margin: 20mm;
      size: auto;
    }
  `
  
  function handleSubmitModal(item: any, ind: any, groupIndx?: any) {
    if(hasGroup) {
      setReports((oldData: any[]) => {
        return oldData.map((dataItem: any, index: any) => {
          if(index === ind) {
            const _produtos = [...dataItem.agrupados];
            const _produto = dataItem.agrupados[groupIndx];
            const updateItem = {
              ..._produto,
              codigo: item.codigo,
              nome: item.nome,
              Classe: item.Classe.nome,
              UnidadeMedida: item.UnidadeMedida.codigo,
              ean: item.ean,
              NCM: item.NCM.codico,
              Procedencia: item.Procedencia.codigo
            };
            _produtos[groupIndx] = updateItem;
            dataItem.agrupados = _produtos;

            return dataItem;
          } else {
            return dataItem;
          }
        })
      })
    } else {
      setReports((oldData: any[]) => {
        return oldData.map((dataItem: any, index: any) => {
          if (index === ind) {
            const updateItem = {
              ...item,
              Classe: item.Classe.nome,
              UnidadeMedida: item.UnidadeMedida.codigo,
              ean: item.ean,
              NCM: item.NCM.codico,
              Procedencia: item.Procedencia.codigo
            }
            return {
              ...dataItem,
              ...updateItem,
            }
          } else {
            return dataItem;
          }
        });
      })
    }
    closeModal();
  }

  function handleOpenModal(item: any, index: number, groupIndex?: number) {
    openModal({
      type: 'confirm',
      title: `Editar ${filters.classe}`,
      children: () => <FormTemplate
        filters={{ ...filters }}
        customFields={{}}
        title={title}
        endpoint={`${endpoint}`}
        onSubmitCallback={(item: any) => handleSubmitModal(item, index, groupIndex)}
        modalItem={item}
        getById />,
    })
  }

  function checkIsOpen(item: any) {
    const _find = openList.find((op: any) => {
      if (item.hasOwnProperty('UF') && op === item.UF) {
        return true;
      }
      if (op === item.nome) {
        return true;
      }
      return false;
    });

    if(_find === null) {
      return true
    }

    return _find;
  }
  
  function handleOpen(item: any) {
    if (item.hasOwnProperty('UF')) {
      const _nome = item.UF;
      const indexOf = openList.findIndex((it: any) => it === item.UF);

      if (indexOf > -1) {
        const newList = [...openList];
        newList.splice(indexOf, 1);
        setOpenList(newList);
      } else {
        const newList = [...openList, _nome];
        setOpenList(newList);
      }
    } else {
      const _nome = item.nome;
      const indexOf = openList.findIndex((it: any) => it === item.nome);

      if (indexOf > -1) {
        const newList = [...openList];
        newList.splice(indexOf, 1);
        setOpenList(newList);
      } else {
        const newList = [...openList, _nome];
        setOpenList(newList);
      }
    }
  }
  
  function _renderClassTr(item: any, index: any = 0) {
    let _hasChildren: any = []
    if(reports.agrupados){
      _hasChildren = reports.filter((report:any) => report.agrupados.find((forn:any) => forn.Classe === item.nome));
    } else {
      _hasChildren = reports.filter((report:any) => report.Classe.includes(item.nome));
    }
    if (_hasChildren.length <= 0) {
      return (
        <tr>
          <td colSpan={fields.length}><b>Nenhum resultado para estas variáveis</b></td>
        </tr>
      )
    }
    return <>
      <tr className='button'>
        <td>
          <div onClick={() => handleOpen(item)} className='titleContainer'>
            <div className='plusBox' style={{marginLeft: (index)*10}}>
              {checkIsOpen(item) ? <FaMinusSquare color='white' size={14} /> : <FaPlusSquare color='white' size={14} />}
            </div>
            <h4>{item.nome}</h4>
          </div>
        </td>
        {fields.map((_item, index) => index !== 0 && (
          <td key={index}></td>
        ))}
      </tr>
      {checkIsOpen(item) && reports[0].agrupados ? <>
        {reports.map((fornecedor: any, indexFor: any) => fornecedor.agrupados.map((forn:any, indexFornec: any) => item.nome === forn.Classe && (
            <tr className={marcaTexto ? 'yellow' : ''} key={indexFor}>
              {fields.map(field => {
                const value = forn[field.field];
                
                if (field.field === 'celular' || field.field === 'telefone') {
                  const value_formated = formatPhoneNumber(value) ?? value;
                  return (<td>{value_formated}</td>)
                } else if (field.field === 'vrEnt' || field.field === 'vrSai' || field.field === 'vrSaldo' || field.field === 'prMedio') {
                  const value_formated = value ? parseFloat(value).toFixed(2) : '';
                  return (<td className={value_formated[0] && value_formated[0] === '-' ? 'money red-color-cell' : 'money'}>{value_formated}</td>)
                } else if (field.field === 'qEnt' || field.field === 'qSai' || field.field === 'qSaldo') {
                  return (<td className={value && String(value) && String(value)[0] === '-' ? 'number-alignment red-color-cell' : 'number-alignment'}>{value}</td>)
                } else if (field.field === 'cpfCnpj' || field.field === 'codigo') {
                  return <td className='no-wrap'><span onClick={() => handleOpenModal(forn, indexFor, indexFornec)}>{formatCNPJ(value) || value}</span></td>
                } else if (field.field === 'UF' || field.field === 'Cidade') {
                  return <td>{forn["Cidade"]}-{forn[field.field]}</td>
                } else if(field.field === 'Cidade') {
                  return null;
                }
                return (
                  <td className={value && String(value) && String(value)[0] === '-' ? 'red-color-cell' : ''}>{value ?? ''}</td>
                )
              }
              )}
            </tr> )
          ))}
        {item.children && item.children.map((it:any) => _renderClassTr(it, index+1))}
        </> 
      : null}
    </>
  }

  function _renderAgrupamento() {};

  function renderTable() {
    if (agrupamento) return _renderAgrupamento();
    if (hasGroup) {
      if (group === 'Classe') {
        return classes.map((item: any, index: any) => _renderClassTr(item))
      } else {
        if (reports && reports.length <= 0) {
          return (
            <tr>
              <td colSpan={fields.length}><b>Nenhum resultado para estas variáveis</b></td>
            </tr>
          )
        }
        return reports ? reports?.map((item: any, index: any) => (<>
          <tr className='button'>
            <td>
              <div onClick={() => handleOpen(item)} className='titleContainer'>
                <div className='plusBox'>
                  {checkIsOpen(item) ? <AiOutlineMinus color='white' size={14} /> : <AiOutlinePlus color='white' size={14} />}
                </div>
                <h4>{item.hasOwnProperty('UF') ? item.UF : item.Classe ? item.Classe : item.Agrupador}</h4>
              </div>
            </td>
            {fields.map((_item, index) => index !== 0 && (
              <td key={index}></td>
            ))}
          </tr>
          {checkIsOpen(item) && item.agrupados.map((fornecedor: any, indexFor: any) => (
            <tr className={marcaTexto ? 'yellow' : ''} key={indexFor}>
              {fields.map(field => {
                const value = fornecedor[field.field];
  
                if (field.field === 'celular' || field.field === 'telefone') {
                  const value_formated = formatPhoneNumber(value) ?? value;
                  return (<td>{value_formated}</td>)
                } else if (field.field === 'vrEnt' || field.field === 'vrSai' || field.field === 'vrSaldo' || field.field === 'prMedio') {
                  const value_formated = value ? parseFloat(value).toFixed(2) : '';
                  return (<td className={value_formated[0] && value_formated[0] === '-' ? 'money red-color-cell' : 'money'}>{value_formated}</td>)
                } else if (field.field === 'qEnt' || field.field === 'qSai' || field.field === 'qSaldo') {
                  return (<td className={value && String(value) && String(value)[0] === '-' ? 'number-alignment red-color-cell' : 'number-alignment'}>{value}</td>)
                }  else if (field.field === 'cpfCnpj' || field.field === 'codigo') {
                  return <td className='no-wrap'><span onClick={() => handleOpenModal(fornecedor, index, indexFor)}>{formatCNPJ(value) || value}</span></td>
                } else if (field.field === 'UF' || field.field === 'Cidade') {
                  return <td>{fornecedor["Cidade"]}-{fornecedor[field.field]}</td>
                } else if(field.field === 'Cidade') {
                  return null;
                }
                return (
                  <td>{value ?? ''}</td>
                )
              }
              )}
            </tr>
          ))}
        </>)) : null
  
      }
    }

    return reports ? reports?.map((item: any, index: any) => {
      return (
        <tr className={marcaTexto ? 'yellow' : ''} style={{textAlign: 'left'}}>
          {fields.map(field => {
            const value = item[field.field];

            if (field.field === 'celular' || field.field === 'telefone') {
              const value_formated = formatPhoneNumber(value) ?? value;
              return (<td>{value_formated}</td>)
            } else if (field.field === 'vrEnt' || field.field === 'vrSai' || field.field === 'vrSaldo' || field.field === 'prMedio') {
              const value_formated = value ? parseFloat(value).toFixed(2) : '';
              return (<td className={value_formated[0] && value_formated[0] === '-' ? 'number-alignment red-color-cell' : 'number-alignment'}>{value_formated}</td>)
            } else if (field.field === 'qEnt' || field.field === 'qSai' || field.field === 'qSaldo') {
              return (<td className={value && String(value) && String(value)[0] === '-' ? 'number-alignment red-color-cell' : 'number-alignment'}>{value}</td>)
            } else if (field.field === 'cpfCnpj' || field.field === 'codigo') {
              return <td className='no-wrap'><span onClick={() => handleOpenModal(item, index)}>{formatCNPJ(value) || value}</span></td>
            } else if (field.field === 'UF' || field.field === 'Cidade') {
              return <td>{item["Cidade"]}-{item[field.field]}</td>
            } else if(field.field === 'Cidade') {
              return null;
            }
            return (
              <td>{value ?? ''}</td>
            )
          }
          )}
        </tr>
      )
    }) : null
  }

  function handleOpenResumo() {
    let dtEmissao = convertToDate(date);
    let emissao = dtEmissao ? format(dtEmissao, 'dd/MM/yy eeeeee HH:mm', {
      locale: ptBR,
    }) : date;
    const copyFilters = [
      {
        label: 'Data',
        value: emissao,
      },
      {
        label: 'Versão do Sistema',
        value: VERSION,
      },
      {
        label: 'Caminho',
        value: 'Compras \\ Relatórios \\ Relatório de Produtos',
      },
      ...filterLabel,
    ];
    openModal({
      title: `Resumo Variáveis`,
      children: () => <ResumeFiltersReport title={title} filters={copyFilters} />
    })
  }
  
  return (
    <Main>
      <Row>
        <Button onClick={() => history.goBack()}>Voltar</Button>
        <RightButtons>
          <Button onClick={handleOpenResumo}>Resumo</Button>
          <ReactToPrint
            trigger={() => {
              return <Button type='button' className='buttonPrint'>Imprimir</Button>
            }}
            content={() => componentRef.current}
            documentTitle="new document"
            pageStyle={pageStyle}
          />
        </RightButtons>
      </Row>

      <Container ref={componentRef}>
        <DivRow>
          {license && (
            <Row>
              <p>{license.nomeFantasia}</p>
              <label>Versão do Sistema: {VERSION}</label>
            </Row>
          )}
          <Row>
            <p>{title}</p>
            <label>{formatDateHorsComDiaSemana(date)}</label>
          </Row>
          <Row>
            <p>Compras \ Relatórios \ Relatório de Produtos</p>
          </Row>
          {gerarCabecalho && (
            <FiltersContainer>
              <p className='title'>Filtros:</p>
              <p>
                {filterLabel.map((filter:any, index:any) => {
                  let value = '';
                  
                  if(Array.isArray(filter.value)){
                    if (filter.value.length === 0) value = 'Nenhum';
                    value = filter.value.map((it:any) => `${it} `);
                  } else {
                    if (filter.value instanceof Date) {
                      value = formatDate(filter.value);
                    } else {
                      value = filter.value;
                    }
                  }
                  return(
                    <label className='label'>
                      {filter.label} - <span className='span'>{value}</span>
                    </label>
                  )
                })}
              </p>
            </FiltersContainer>
          )}
        </DivRow>

        <DivItems>
          <table>
            <thead>
              <tr style={{textAlign: 'left'}}>
                {fields.map((field) => <th key={field.field}>
                  {field.label === 'UF' ? 'Cidade-UF' : field.label}
                  </th>
                )}
            </tr>
            </thead>
            {renderTable()}
          </table>
        </DivItems>

        <LogoFooter>
          <img src={LogoDevari} alt="Logo Devari" />
        </LogoFooter>
      </Container>
    </Main>
  )
}

export default ProductsReport;
