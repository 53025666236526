/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Container } from "react-grid-system";
import {
  Wrapper,
  Title,
  Content,
  HeaderContent,
  ActionsContainer,
  Button,
} from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import Table from "../../../components/Table";
import Toast from "../../../components/Toast";
import CardTable from "../../../components/CardTable";
import {
  getChavePedido,
} from "../../../services/pedidos";
import { convertToDate } from "../../../utils/date";
import { detalhesData, itemCols, tituloCols } from "./fixedData";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

import { useAuth } from "../../../contexts/auth";
import {
  baixarRequerimento,
  calcularRequerimento,
  criarRequerimento,
  deleteItem,
  getItems,
  updateRequerimento,
  getMovimentacoesRequerimento,
} from "../../../services/requerimento";
import { useModal } from "../../../contexts/modal";

interface iSalesOrder {
  baixaAutom?: boolean;
}

const NewRequest: React.FC<iSalesOrder> = ({ baixaAutom }) => {
  const history = useHistory();
  const location: any = useLocation();
  let path = location.pathname;
  const { openModal, closeModal } = useModal();
  const baixaAutomatica = baixaAutom
    ? baixaAutom
    : location.state &&
      location.state.baixaAutomatica &&
      location.state.baixaAutomatica === true;
  const baixaNaoAutomatica =
    location.state && location.state.baixa && location.state.baixa !== null;
  const baixaPedido =
    location.state && location.state.baixa ? location.state.baixa : null;
  const itemEdicao =
    location.state && location.state.item ? location.state.item : null;
  const { user } = useAuth();
  const novoPedido = location.state && location.state.item ? false : true;
  const [aprovador, setAprovador] = useState<any>(
    user ? (user.nome ? user.nome : user.email ? user.email : user.id) : null
  );
  const [payload, setPayload] = useState<any>({
    emissao: new Date().toISOString(),
    observacao: null,
    frete: null,
    seguro: null,
    acrescimo: null,
    desconto: null,
    idAcessorio1: null,
  });
  const [faturamento, setFaturamento] = useState({
    movimentacao: new Date().toISOString(),
    observacaoFiscal: "",
    numero: "",
    serie: "",
  });
  const [loadings, setLoadings] = useState({
    aprovar: false,
    movimentacao: false,
    calcular: false,
    salvar: false,
    excluir: false,
  });
  const [titulosFixedValue, setTitulosFixedValue] = useState<any>({});
  const [titulosFixedValues, setTitulosFixedValues] = useState<any>({});
  const [comAprovador, setComAprovador] = useState(false);
  const [chaveCriacao, setChaveCriacao] = useState(0);
  const [selected, setSelected] = useState<boolean[]>([]);
  const [initialChaves, setInitialChaves] = useState<any>([]);
  const [approvedItems, setApprovedItems] = useState<any>([]);
  const [movimentItems, setMovimentItems] = useState<any>([]);
  const [observacaoToShow, setObservacaoToShow] = useState("");
  const [lookups, setLookups] = useState<any>({});
  const [initialLookups, setInitialLookups] = useState<any>({
    Classe: null,
    ccustres: null,
    Estabelecimento: null,
    Requisitante: null,
  });
  const [changedCondicaoPagamento, setChangedCondicaoPagamento] =
    useState(false);
  const [initialItems, setInitialItems] = useState<any>({
    idClasse: null,
    idccustres: null,
    idRecurso: null,
    idLote: null,
    idRequisitante: null,
  });
  const [initialTitulos, setInitialTitulos] = useState<any>(null);
  const [items, setItems] = useState<any>(null);
  const [titulos, setTitulos] = useState<any>(null);
  const [colunasTitulos, setColunasTitulos] = useState<any>(tituloCols());
  const [colunasItens, setColunasItens] = useState<any>(itemCols());
  const [totais, setTotais] = useState<any>({
    acrescimoItemTotal: 0,
    descontoItemTotal: 0,
    ipiTotal: 0,
    valorTotal: 0,
    valorCorrigidoTotal: 0,
    vrItemTotal: 0,
    items: [],
    titulos: [],
    condicoesPagamento: [],
  });

  useEffect(() => {
    if (lookups.CondicaoPagamento && lookups.CondicaoPagamento.value) {
      if (!changedCondicaoPagamento) {
        setChangedCondicaoPagamento(true);
      }
    }
  }, [lookups]);

  useEffect(() => {
    if (!baixaAutomatica && !baixaNaoAutomatica && !itemEdicao && user) {
      if (user.Estab) {
        setInitialLookups((oldData: any) => {
          return {
            ...oldData,
            Estabelecimento: { byId: user.Estab.id },
          };
        });
      }
      if (user.LocEscrit) {
        setInitialLookups((oldData: any) => {
          return {
            ...oldData,
            LocEscrituracao: { byId: user.LocEscrit.id },
          };
        });
      }
    }
  }, [user, baixaNaoAutomatica, baixaNaoAutomatica, itemEdicao]);

  useEffect(() => {
    if (baixaNaoAutomatica || baixaAutomatica) return;
    const timeOutId = setTimeout(
      () => setObservacaoToShow(payload.observacao),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [payload.observacao]);

  useEffect(() => {
    if (baixaPedido) {
      loadInitialState(baixaPedido, true);
    }
  }, [baixaPedido]);

  useEffect(() => {
    if (itemEdicao) {
      loadInitialState(itemEdicao);
    }
  }, [itemEdicao]);

  useEffect(() => {
    if (novoPedido) {
      getChaveCriacao();
    }
  }, [novoPedido]);

  useEffect(() => {
    handleAprovacao();
  }, [comAprovador]);

  function loadCondicoesCalc(condicoes: any[]) {
    const initialTitulosData: any[] = [];
    for (const titulo of condicoes) {
      let tituloInitialState: any = {
        Carteira: titulo.Carteira ? titulo.Carteira : null,
        TipoDocumento: titulo.TipoDocumento ? titulo.TipoDocumento : null,
        juros: titulo.juros ? parseFloat(titulo.juros) : null,
        juroPercm: titulo.juroPercm ? titulo.juroPercm : null,
        multa: titulo.multa ? parseFloat(titulo.multa) : null,
        multaPerc: titulo.multaPerc ? titulo.multaPerc : null,
        acrescimo: titulo.acrescimo ? parseFloat(titulo.acrescimo) : null,
        desconto: titulo.desconto ? parseFloat(titulo.desconto) : null,
        correcao: titulo.correcao
          ? new Date(titulo.correcao).toISOString()
          : null,
        prazo: titulo.hasOwnProperty("prazo") ? parseInt(titulo.prazo) : 0,
        vencimento: titulo.vencimento
          ? new Date(titulo.vencimento).toISOString()
          : new Date().toISOString(),
      };
      initialTitulosData.push(tituloInitialState);
    }
    setInitialTitulos((oldData: any) => {
      return initialTitulosData;
    });
    setChangedCondicaoPagamento(false);
  }

  async function loadInitialState(pedido: any, baixa: boolean = false) {
    if (!baixa) {
      setChaveCriacao(pedido.chcriacao);
      if (pedido.idAprovador) {
        // setComAprovador(true);
        setAprovador(
          pedido.Aprovador
            ? pedido.Aprovador.nome
              ? pedido.Aprovador.nome
              : pedido.Aprovador.codigo
              ? pedido.Aprovador.codigo
              : "Requisição aprovada"
            : "Requisição aprovada"
        );
      }
    }
    setInitialLookups({
      Representante: pedido.Representante
        ? { byId: pedido.Representante.id }
        : null,
      LocEscrituracao: pedido.LocEscritu
        ? { byId: pedido.LocEscritu.id }
        : null,
      Estabelecimento: pedido.Estabelecimento
        ? { byId: pedido.Estabelecimento.id }
        : null,
      Pessoa: pedido.Pessoa ? { byId: pedido.Pessoa.id } : null,
    });

    const tempTotais: any = {
      acrescimoItemTotal: 0,
      descontoItemTotal: 0,
      ipiTotal: 0,
      valorTotal: 0,
      valorCorrigidoTotal: 0,
      vrItemTotal: 0,
      items: [],
      titulos: [],
      condicoesPagamento: [],
    };
    const initialItemsData = [];
    const initialTitulosData = [];
    const itemsChave = [];
    for (const item of pedido.items) {
      let itemInitialState: any = {
        classeRequisicao: item.Classe ? item.Classe : null,
        centroCusto: item.ccustres ? item.ccustres : null,
        emissao: item.emissao ? item.emissao : null,
        Recurso: item.DRequisicRecurso ? item.DRequisicRecurso : null,
        Nucleo: item.Nucleo ? item.Nucleo : null,
        Lote: item.Lote ? item.Lote : null,
        quantidade: item.quantidade ? parseInt(item.quantidade) : null,
        unitario: item.unitario ? parseFloat(item.unitario) : null,
        observacao: item.observacao ? item.observacao : null,
        requisitante: item.requisitante ? item.requisitante : null,
        aprovacao: item.aprovacao ? item.aprovacao : null,
        acrescimoItem: item.acrescimoItem
          ? parseFloat(item.acrescimoItem)
          : null,
        descontoItem: item.descontoItem ? parseFloat(item.descontoItem) : null,
        ipi: item.ipi ? parseFloat(item.ipi) : null,
      };
      if (baixa) {
        itemInitialState = {
          ...itemInitialState,
          chpedbaixa: item.chpedbaixa ? item.chpedbaixa : null,
        };
      } else {
        itemsChave.push(item.chave);
        itemInitialState = {
          ...itemInitialState,
          chave: item.chave ? item.chave : null,
        };
      }
      initialItemsData.push(itemInitialState);
    }

    if (pedido.titulos) {
      for (const titulo of pedido.titulos) {
        let prazo = 0;
        if (titulo.correcao) {
          prazo = differenceInCalendarDays(
            new Date(titulo.correcao),
            new Date(pedido.emissao)
          );
        } else {
          prazo = differenceInCalendarDays(
            new Date(titulo.vencimento),
            new Date(pedido.emissao)
          );
        }
        let tituloInitialState: any = {
          Carteira: titulo.Carteira ? titulo.Carteira : null,
          TipoDocumento: titulo.TipoDocumento ? titulo.TipoDocumento : null,
          juros: titulo.juros ? parseFloat(titulo.juros) : null,
          juroPercm: titulo.juroPercm ? titulo.juroPercm : null,
          multa: titulo.multa ? parseFloat(titulo.multa) : null,
          multaPerc: titulo.multaPerc ? titulo.multaPerc : null,
          acrescimo: titulo.acrescimo ? parseFloat(titulo.acrescimo) : null,
          desconto: titulo.desconto ? parseFloat(titulo.desconto) : null,
          correcao: titulo.correcao
            ? new Date(titulo.correcao).toISOString()
            : null,
          prazo,
        };

        if (!baixa) {
          tituloInitialState = {
            ...tituloInitialState,
            chave: titulo.chave ? titulo.chave : null,
          };
        }
        initialTitulosData.push(tituloInitialState);
      }
    }
    setInitialItems(initialItemsData);
    setInitialTitulos(initialTitulosData);
    await getMovimentacoes(itemsChave);
    setInitialChaves(itemsChave);
  }

  async function getMovimentacoes(chaves: any[]) {
    try {
      const movimentacoes = await getMovimentacoesRequerimento(chaves);
      const movs: any[] = [];
      for (const item of movimentacoes) {
        let mov: any = {
          chave: item.chave,
          chaveReq: item.chcriacao,
          dataDaida: item.movimentacao ? new Date(item.movimentacao).toISOString() : new Date().toISOString(),
          Deposito: item.idEntidade ? { byId: item.idEntidade} : null,
          Lote: item.idLote ? { byId: item.idLote} : null,
          quantidade: item.quantidade ? item.quantidade * -1 : 0,
          custo: item.custo ? item.custo : 0,
        }
        movs.push(mov);
      }
      setMovimentItems(movs);
    } catch (err: any) {
      Toast.show(err.message, 'error');
    }
  }

  function handleAprovacao() {
    if (comAprovador) {
      setColunasItens((oldValue: any) =>
        oldValue.map((item: any) => {
          return { ...item, editavel: false };
        })
      );
      setColunasTitulos((oldValue: any) =>
        oldValue.map((item: any) => {
          return { ...item, editavel: false };
        })
      );
    } else {
      setColunasItens(itemCols());
      setColunasTitulos(tituloCols());
    }
  }

  async function getChaveCriacao() {
    try {
      const result = await getChavePedido(user?.id);
      setChaveCriacao(result.chave);
    } catch (_err) {
      return Toast.show(
        "Ocorreu um erro ao tentar buscar a chave de criação",
        "error"
      );
    }
  }

  function validatePayload(): boolean {
    if (!payload.emissao) {
      Toast.show("Insira a data de emissão");
      return false;
    }

    if (baixaAutomatica || baixaNaoAutomatica) {
      if (!faturamento.movimentacao) {
        Toast.show("Insira a data de movimentação do faturamento");
        return false;
      } else if (!faturamento.serie) {
        Toast.show("Insira a série do faturamento");
        return false;
      } else if (!faturamento.numero) {
        Toast.show("Insira o número do faturamento");
        return false;
      }

      if (!lookups.TipoDoc || !lookups.TipoDoc.value) {
        Toast.show("Insira o tipo de documento do faturamento");
        return false;
      }
    }
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (!item.idRecurso) {
        Toast.show(`Insira o recurso do item ${i + 1}`);
        return false;
      } else if (!item.idclasseRequisicao) {
        Toast.show(`Insira a classe da requisição`);
        return false;
      } else if (!item.idcentroCusto) {
        Toast.show(`Insira o centro de custo`);
        return false;
      } else if (!item.quantidade) {
        Toast.show(`Insira a quantidade do item ${i + 1}`);
        return false;
      }
    }

    return true;
  }

  function handleChangeLoading(field: string, value: boolean) {
    return setLoadings((oldValue) => {
      return { ...oldValue, [field]: value };
    });
  }

  async function handleCalcular(): Promise<boolean> {
    handleChangeLoading("calcular", true);

    if (validatePayload()) {

      let itemToCalc = items.map((item: any) => {
        return {
          chave: item.chave,
          idRecurso: item.idRecurso,
          quantidade: item.quantidade,
          idLote: item.idLote,
          idcentroCusto: item.idcentroCusto,
        };
      });
      try {
        const result = await calcularRequerimento(itemToCalc);

        setTotais((oldValue: any) => {
          const toUpdateTotais = { ...oldValue, items: result };
          return toUpdateTotais;
        });

        handleChangeLoading("calcular", false);
      } catch (err: any) {
        Toast.show(err.message);
        handleChangeLoading("calcular", false);
        return false;
      }
    } else {
      handleChangeLoading("calcular", false);
      return false;
    }

    handleChangeLoading("calcular", false);
    return true;
  }

  async function handleSalvar() {
    handleChangeLoading("salvar", true);
    try {
      let toSendPayload: any = {
        tipo: "requisicao",
        baixaAutomatica,
        baixaId: user?.id,
        idAprovador: null,
        aprovacao: null,
        observacao: payload.observacao,
        emissao: convertToDate(payload.emissao),
        total: totais,
        idAcessorio1: payload.idAcessorio1 ? payload.idAcessorio1 : "",
        ItensDoPedido: items.map((_item: any, index: number) => {
          let item = { ..._item, chcriacao: chaveCriacao };
          if(approvedItems.length > 0){
            const isApproved = approvedItems.filter((it: any) => it !== null && it.chave === item.chave)[0];
            if (isApproved) {
              item = {...item, idAprovador: user?.id, aprovacao: new Date()}
            } else {
              item = { ...item, idAprovador: null, aprovacao: null }
            }
          }
          return item;
        }),
        Movimentacoes: titulos,
      };
      await criarRequerimento(toSendPayload);
      handleChangeLoading("salvar", false);
      Toast.show(
        `A requisição foi criada com sucesso.`,
        "success"
      );
      history.push("/requisicoes");
    } catch (error: any) {
      handleChangeLoading("salvar", false);
      Toast.show(error.message);
    }
  }

  async function handleMovimentacao() {
    handleChangeLoading("movimentacao", true);
    try {
      const movs: any[] = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (selected[i] && selected[i] === true) {
          const isApproved = approvedItems.filter((it: any) => it !== null && it.chave === item.chave)[0];
          if (isApproved) {
            let mov: any = {
              chaveReq: item.chave,
              dataDaida: item.emissao ? new Date(item.emissao).toISOString() : new Date().toISOString(),
              Lote: item.idLote ? { byId: item.idLote} : null,
              quantidade: item.quantidade ? item.quantidade : 0,
              custo: item.unitario ? item.unitario : 0,
            }
            movs.push(mov);
          }
        }
      }

      setMovimentItems(movs);
      handleChangeLoading("movimentacao", false);
    } catch (err: any) {
      handleChangeLoading("movimentacao", false);
      Toast.show(err.message);
    }
  }

  async function handleExcluir() {
    handleChangeLoading("excluir", true);
    try {
      const result = await deleteItem(chaveCriacao);
      if (result) {
        handleChangeLoading("excluir", false);
        history.goBack();
      }
    } catch (err: any) {
      handleChangeLoading("excluir", false);
      Toast.show(err.message);
    }
  }

  const handleModalDeleteRequisic = () => {
    openModal({
      type: "confirm",
      title: "Excluir baixa",
      children: renderDeleteContent,
      onConfirm: () => handleExcluir(),
      onCancel: closeModal(),
      closeOnAction: false,
    });
  };

  const renderDeleteContent = () => {
    return (
      <>
        <h3 style={{ marginTop: 2, marginBottom: 10, fontWeight: 400 }}>
          Tem certeza que deseja excluir?
        </h3>
        <p>Esta ação é irreversível.</p>
      </>
    );
  };

  async function handleSalvarUpdate() {
    try {
      let toSendPayload: any = {
        tipo: "requisicao",
        baixaAutomatica,
        baixaId: user?.id,
        idAprovador: null,
        aprovacao: null,
        observacao: payload.observacao,
        emissao: convertToDate(payload.emissao),
        total: totais,
        idAcessorio1: payload.idAcessorio1 ? payload.idAcessorio1 : "",
        ItensDoPedido: items.map((_item: any, index: number) => {
          let item = { ..._item, chcriacao: chaveCriacao };
          if(approvedItems.length > 0){
            const isApproved = approvedItems.filter((it: any) => it !== null && it.chave === item.chave)[0];
            if (isApproved) {
              item = {...item, idAprovador: user?.id, aprovacao: new Date()}
            } else {
              item = { ...item, idAprovador: null, aprovacao: null }
            }
          }
          return item;
        }),
        Movimentacoes: titulos,
        chavesIniciais: initialChaves,
      };
      await updateRequerimento(toSendPayload);
      handleChangeLoading("salvar", false);
      Toast.show(
        `A requisição foi atualizada com sucesso.`,
        "success"
      );
      history.push("/requisicoes");
    } catch (error: any) {
      handleChangeLoading("salvar", false);
      Toast.show(error.message);
    }
  }

  const handleApprovedItems = () => {
    handleChangeLoading("aprovar", true);
    const index = selected
    const _items = [...items]
    const itSelected: any = []

    if(index.length > 0){
      for (let i = 0; i < _items.length; i++) {
        const selec = index[i];
        if (selec && selec === true) {
          itSelected.push(_items[i]);
        } else {
          itSelected.push(null);
        }
      }
    } else{
      Toast.show('Selecione os itens que deseja aprovar')
    }
    
    handleChangeLoading("aprovar", false);
    setApprovedItems(itSelected);
  }
  
  async function handleSubmit(e: any) {
    let tipo, calcular, titulos, baixaOK, podeDesaprovar;
    if (e) {
      e.preventDefault();
      tipo = e.nativeEvent.submitter.name;
    }
    switch (tipo) {
      case "salvar":
        calcular = await handleCalcular();
        if (calcular) {
          if (itemEdicao) {
            await handleSalvarUpdate();
          } else {
            await handleSalvar();
          }
        }
        break;
      case "aprovar":
        calcular = await handleCalcular();
        if (calcular) {
          handleApprovedItems();
        }
        break;
      case "calcular":
        await handleCalcular();
        break;
      case "movimentacao":
        if (approvedItems.length > 0) {
          await handleMovimentacao();
        } else {
          Toast.show("É preciso aprovar a requisição antes de gerar movimentação");
        }
        break;
      case "excluir":
        handleModalDeleteRequisic();
        break;
      default:
        break;
    }
  }

  function handleChangeTitulos(result: any) {
    setTitulos(result);
  }

  return (
    <Wrapper>
      <HeaderContent>
        <Title>Requisição</Title>
        <ActionsContainer>
          <Button
            form="custom-form"
            type="submit"
            id="movimentacao"
            name="movimentacao"
            disabled={loadings.movimentacao}
          >
            {loadings.movimentacao ? "Gerando..." : "Gerar Movimentações"}
          </Button>
          {!baixaNaoAutomatica && (
            <Button
              form="custom-form"
              type="submit"
              id="aprovar"
              name="aprovar"
              disabled={loadings.aprovar}
            >
              {loadings.aprovar
                  ? "Aprovando..."
                  : "Aprovar"}
            </Button>
          )}
          <Button
            form="custom-form"
            type="submit"
            id="calcular"
            name="calcular"
            disabled={loadings.calcular}
          >
            {loadings.calcular ? "Calculando..." : "Calcular"}
          </Button>
          <Button
            form="custom-form"
            type="submit"
            id="salvar"
            name="salvar"
            disabled={loadings.salvar}
          >
            {loadings.salvar ? "Gravando..." : "Gravar"}
          </Button>
          <Button type="button" onClick={() => history.goBack()}>
            Fechar
          </Button>
        </ActionsContainer>
      </HeaderContent>
      <Content id="custom-form" onSubmit={handleSubmit}>
        <Container fluid style={{ width: "100%" }}>
          <Table
            actions={["insert", "delete", "select"]}
            title="Itens"
            onChange={(result: any) => setItems(result)}
            fromForm
            changeSelectedIndexes={setSelected}
            withTotal
            isRecurso
            initialValue={initialItems}
            chaveCriacao={chaveCriacao}
            lookupsRegras={initialLookups ? initialLookups : lookups}
            fixedValues={totais.items && approvedItems.length > 0 ? totais.items.map((it: any, idx: number) => {
              const isApproved = approvedItems[idx] !== null;
              if (isApproved) {
                return { ...it, aprovacao: new Date().toISOString() };
              } else {
                return it;
              }
            }) : totais.items}
            cols={colunasItens}
          />
          </Container>
        </Content>
        <Content id="custom-form" onSubmit={handleSubmit}>
          <Container fluid style={{ width: "100%" }}>
            <Table
              title="Movimentação"
              actions={["delete"]}
              fromForm
              withTotal
              isRecurso
              initialValue={movimentItems}
              cols={colunasTitulos}
              onChange={handleChangeTitulos}
            />
        </Container>
      </Content>
    </Wrapper>
  );
};

export default NewRequest;
